// Scripts for the general navigation component
import '../../../../styles/src/components/navigation/general.scss';

import '../../vendor/mmenu';

// Mmenu settings
document.addEventListener(
  "DOMContentLoaded", () => {
    // Handle preloading of drawer menus
    if( document.querySelector('.navigation__links') ) {
      let navigationLinks = document.querySelectorAll('.navigation__links');
      navigationLinks.forEach( (navLinks) => {
        navLinks.style.display = 'none';
        navLinks.classList.remove('navigation__links--preload');
        navLinks.style.display = '';
      });
    }

    if( document.querySelector('.navigation__nav') ) {
      let navNav = document.querySelectorAll('.navigation__nav');
      navNav.forEach( (nav) => {
        nav.style.display = 'none';
        nav.classList.remove('navigation__nav--preload');
        nav.style.display = '';
      });
    }

    new Mmenu( '#drawer-nav', {
      // options
      "extensions": [
        "position-front",
        "position-right"
      ],
      wrappers: [
        "wordpress"
      ]
    }, {
      // configuration
      offCanvas: {
        page: {
          selector: "#page-wrapper"
        }
      }
    });
  }
);

// Control scroll class to navigation
if( document.querySelector('.navigation') ) {
  window.addEventListener('scroll', () => {
    let navigation = document.querySelector('.navigation');
    if( document.documentElement.scrollTop > 300 ){
      navigation.classList.add('navigation--has-scrolled');
    } else {
      navigation.classList.remove('navigation--has-scrolled')
    }
  });
}

// Toggles the search drawer after removing preload styles and hiding
if( document.querySelector('.navigation__search-drawer') ) {
  let searchDrawer = document.querySelector('.navigation__search-drawer');
  searchDrawer.style.display = 'none';
  searchDrawer.classList.remove('navigation__search-drawer--preload');
  searchDrawer.style.display = '';

  let searchToggle = document.querySelector('.navigation__toggle--search');
  searchToggle.addEventListener( 'click', () => {
    document.querySelector('.navigation__search-drawer').classList.toggle('navigation__search-drawer--is-active');
  });
}
